import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { link } from 'fs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Rem } from './reminder';

@Injectable({
  providedIn: 'root'
})
export class ReminderService {
  apiUrl = environment.apiUrl;
  url = this.apiUrl + 'Reminder/';
  public dataSource;
  reminderLink = '';

  NewNotificationsCount = 0;
  constructor(private _http: HttpClient) {

  }

  GetReminderList(Params): Observable<Rem[]> {
    let actionUrl = 'RemindersList';
    return this._http.post<any[]>(this.url + actionUrl, Params);
  }





  ReadReminder(Params) {
    let actionUrl = 'UpdateReminder';
    return this._http.put(this.url + actionUrl, Params);
  }
  updateReminder(Params) {
    let actionUrl = 'UpdateRemindersList';
    return this._http.put(this.url + actionUrl, Params);
  }
  setReminder(v) {
    this.dataSource = v;
    this.dataSource.forEach(element => {
      element.AdditionalData=JSON.parse(element.AdditionalData)
    });
    console.warn(this.dataSource)
  }

  Getreminder(Params) {
    let actionUrl = 'GetReminder';
    return this._http.get(this.url + actionUrl, { params: { id: Params } });
  }
  DeleteReminder(Id) {
    let actionUrl = Id;
    return this._http.delete(this.url + 'DeleteReminder/' + actionUrl);
  }
  GetreminderLink(Reminder) {
    let src = Reminder.AdditionalData;
    let link = '';
    if (Reminder.CategoryLKPId == 363) {
      link = '/dashboard';
    } else if (Reminder.CategoryLKPId == 364) {
      if (Reminder.TypeLKPId == 412) {
        link = '/dashboard/agreements/view/' + src.AgreementId + '/import-results'
      } else if (Reminder.TypeLKPId == 446) {
        link = '/dashboard/agreements/view/' + src.AgreementId + '/import-amounts-results'
      }
      else
        link = '/dashboard/agreements/view/' + src.AgreementId;
    } else if (Reminder.CategoryLKPId == 365) {
      link = '/dashboard/invoices/details/' + src.InvoiceId;
    } else if (Reminder.CategoryLKPId == 366) {
      if (Reminder.TypeLKPId == 447) {
        link = '/dashboard/connections/import-results'
      }
      else link = '/dashboard/connection-profile/' + src.AccountId;
    } else if (Reminder.CategoryLKPId == 367) {
      link = '';
    } else if (Reminder.CategoryLKPId == 368) {
      link = '';
    } else if (Reminder.CategoryLKPId == 369) {
      if (Reminder.TypeLKPId == 187 || Reminder.TypeLKPId == 394 || Reminder.TypeLKPId == 197 || Reminder.TypeLKPId == 205) {
        link = '/dashboard/transactions/details/' + src.TransactionId;
      }
      else link = '/dashboard/payments';
    } else if (Reminder.CategoryLKPId == 714) {
      if (Reminder.TypeLKPId == 716) {
        link = '/dashboard/connection-profile/' + Reminder.SenderAccount.Id + '/ItemDetails/' + src.CatalogueItemId;
      }
    } else if (Reminder.CategoryLKPId == 796) {
      if (Reminder.TypeLKPId == 797) {
        link = '/dashboard/account-settings/logged-in-devices/' + src.LoggedInDeviceId;

      }
    }
    this.reminderLink = link;
  }
}
